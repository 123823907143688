/* titillium-web-200normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Titillium Web Extra Light '),
    local('Titillium Web-Extra Light'),
    url(/static/media/titillium-web-latin-200.352c0596.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-200.c54a0957.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-200italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Titillium Web Extra Light italic'),
    local('Titillium Web-Extra Lightitalic'),
    url(/static/media/titillium-web-latin-200italic.dcc521e4.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-200italic.bec8ef85.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-300normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Titillium Web Light '),
    local('Titillium Web-Light'),
    url(/static/media/titillium-web-latin-300.e7bd2929.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-300.9dd84b03.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-300italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Titillium Web Light italic'),
    local('Titillium Web-Lightitalic'),
    url(/static/media/titillium-web-latin-300italic.29e5a493.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-300italic.b3717846.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-400normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Titillium Web Regular '),
    local('Titillium Web-Regular'),
    url(/static/media/titillium-web-latin-400.811f6a5b.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-400.d8bc9340.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-400italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Titillium Web Regular italic'),
    local('Titillium Web-Regularitalic'),
    url(/static/media/titillium-web-latin-400italic.5b932574.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-400italic.2bf62e42.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-600normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Titillium Web SemiBold '),
    local('Titillium Web-SemiBold'),
    url(/static/media/titillium-web-latin-600.5d5145c1.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-600.7679c7b8.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-600italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Titillium Web SemiBold italic'),
    local('Titillium Web-SemiBolditalic'),
    url(/static/media/titillium-web-latin-600italic.4252e716.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-600italic.ddff3d26.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-700normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Titillium Web Bold '),
    local('Titillium Web-Bold'),
    url(/static/media/titillium-web-latin-700.704fc425.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-700.a804c575.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-700italic - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Titillium Web Bold italic'),
    local('Titillium Web-Bolditalic'),
    url(/static/media/titillium-web-latin-700italic.1f84eb22.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-700italic.af872cf0.woff) format('woff'); /* Modern Browsers */
}

/* titillium-web-900normal - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Titillium Web Black '),
    local('Titillium Web-Black'),
    url(/static/media/titillium-web-latin-900.42fc8f4d.woff2) format('woff2'), 
    url(/static/media/titillium-web-latin-900.c0d751f2.woff) format('woff'); /* Modern Browsers */
}


